import React from "react";
import { Dropdown, DropdownProps } from "../../components/desktop/Dropdown";
import { ItemTypes } from "../NavbarItem";
import { MegaMenu, MegaMenuProps } from "../../components/desktop/MegaMenu";
import { MenuButton, MenuButtonProps } from "../../components/desktop/MenuButton/MenuButton";
import { SearchButton } from "../../components/desktop/SearchButton";

type DesktopItemType = {
  itemType: ItemTypes;
};

export const DesktopNavbarItem: React.FC<MegaMenuProps & DropdownProps & MenuButtonProps & DesktopItemType> = ({
  isActive,
  testSelector,
  icon,
  title,
  activeSubItem,
  dropdownItems = [],
  megaMenuColumns = [],
  itemType,
  customElement,
  openDropdownMenu,
  openMenu,
  setOpenMenu,
  isMobile = false,
  action,
}) => {
  switch (itemType) {
    case ItemTypes.MegaMenu:
      return (
        <MegaMenu
          setOpenMenu={setOpenMenu}
          openMenu={openMenu}
          openDropdownMenu={openDropdownMenu}
          megaMenuColumns={megaMenuColumns}
          isMobile={isMobile}
          testSelector={testSelector}
          title={title}
        />
      );
    case ItemTypes.Dropdown:
      return (
        <Dropdown
          dropdownItems={dropdownItems}
          title={title}
          openMenu={openMenu}
          setOpenMenu={setOpenMenu}
          openDropdownMenu={openDropdownMenu}
          customElement={customElement}
          testSelector={testSelector}
          icon={icon}
          activeSubItem={activeSubItem}
          isMobile={isMobile}
        />
      );
    case ItemTypes.Search:
      return (
        <SearchButton
          testSelector={testSelector}
          title={title}
          action={{ onClick: () => setOpenMenu(true) }}
          icon={icon}
        />
      );
    default:
      return <MenuButton testSelector={testSelector} title={title} isActive={isActive} action={action} icon={icon} />;
  }
};
