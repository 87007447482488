import React from "react";
import { MobileDropdown, MobileDropdownProps } from "../../components/mobile/Dropdown";
import { MobileMegaMenu, MobileMegaMenuProps } from "../../components/mobile/MegaMenu";
import { MobileMenuButton, MobileMenuButtonProps } from "../../components/mobile/MenuButton";
import { ItemTypes } from "../NavbarItem";

type MobileNavbarItemProps = {
  itemType: ItemTypes;
};

export const MobileNavbarItem: React.FC<
  MobileDropdownProps & MobileMegaMenuProps & MobileMenuButtonProps & MobileNavbarItemProps
> = ({
  testSelector,
  isActive,
  title,
  dropdownItems,
  megaMenuColumns,
  setIsDrawerOpen,
  isDrawerOpen,
  itemType,
  action,
}) => {
  switch (itemType) {
    case ItemTypes.Dropdown:
      return (
        <MobileDropdown
          testSelector={testSelector}
          dropdownItems={dropdownItems}
          title={title}
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
        />
      );
    case ItemTypes.MegaMenu:
      return (
        <MobileMegaMenu
          testSelector={testSelector}
          megaMenuColumns={megaMenuColumns}
          title={title}
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
        />
      );
    default:
      return (
        <MobileMenuButton
          testSelector={testSelector}
          isActive={isActive}
          title={title}
          action={action}
          setIsDrawerOpen={setIsDrawerOpen}
          isDrawerOpen={isDrawerOpen}
        />
      );
  }
};
