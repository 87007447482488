import React from "react";

export type ActionProps = {
  href?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
  openInNewTab?: boolean;
};

type AnchorProps = {
  hidden?: boolean;
  testSelector?: string;
  className?: string;
  ref?: React.MutableRefObject<HTMLAnchorElement>;
  action?: ActionProps;
  cleanUp?: () => void;
  tabIndex?: number;
};

export const Link = React.forwardRef<HTMLAnchorElement, React.PropsWithChildren<AnchorProps>>(
  (
    {
      action = { href: null, onClick: undefined },
      testSelector,
      className,
      children,
      cleanUp = () => {},
      hidden = false,
      tabIndex = 0,
    },
    ref
  ) => {
    const handleKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (e.key === "Enter" && action.onClick) {
        action.onClick(e as unknown as React.MouseEvent<HTMLAnchorElement>);
        cleanUp();
      }
    };

    return (
      <a
        tabIndex={tabIndex}
        ref={ref}
        href={action.href}
        hidden={hidden}
        className={className}
        data-testid={testSelector}
        onClick={
          action.onClick
            ? (e) => {
                action.onClick(e);
                cleanUp();
              }
            : undefined
        }
        onKeyDown={handleKeyDown}
        {...(!action?.href && { role: "button" })}
        {...(action?.openInNewTab && { target: "_blank", rel: "noopener noreferrer" })}
      >
        {children}
      </a>
    );
  }
);

Link.displayName = "Link";
