import React, { ReactElement } from "react";
import { ChildrenCategories, NavbarChild } from "./Navbar";
import { DesktopAlign, ItemTypes, MobileMenuType, NavbarItem } from "./NavbarItem";
import { NavbarItemProps, NavItemOptions } from "./NavbarItem/NavbarItem";

type ChildrenSorterProps = {
  children: React.ReactElement[];
  hideNavInHeader: boolean;
  searchCallbacks: {
    setIsOpen: (boolean) => void;
    isOpen: boolean;
  };
  drawerCallbacks: {
    setIsDrawerOpen: (boolean) => void;
    isDrawerOpen: boolean;
  };
  labels?: Record<string, string>;
};

const childrenSorter = ({
  children,
  hideNavInHeader,
  searchCallbacks,
  drawerCallbacks,
  labels = {},
}: ChildrenSorterProps): ChildrenCategories => {
  const leftNavContainer: NavbarChild[] = [];
  const rightNavContainer: NavbarChild[] = [];
  const mainMenuDrawer: NavbarChild[] = [];
  const mobileMenuItems: NavbarChild[] = [];
  let renderCustomHeaderOverflow = null;

  children.forEach((child: React.ReactElement) => {
    let childInstance: React.ReactElement = Object.assign({}, child);
    if (typeof childInstance !== "object") return;
    if (typeof childInstance.type !== "function" || !childInstance.type?.name) return;
    if (childInstance.type.name !== NavbarItem.name || hideNavInHeader) return;
    if (!childInstance?.props?.options) return;
    const childOptions: NavItemOptions = childInstance.props.options;

    if (childOptions?.type === ItemTypes.Search) {
      renderCustomHeaderOverflow = childInstance.props.customElement?.render;
      if (renderCustomHeaderOverflow) {
        childInstance = React.cloneElement(childInstance, searchCallbacks);
      }
    }
    if (childOptions?.mobileMenu === MobileMenuType.Main) {
      childInstance = React.cloneElement(childInstance, drawerCallbacks);
    }
    childInstance = React.cloneElement(childInstance, labels);

    const navbarChild: ReactElement<NavbarItemProps> = childInstance;
    if (!childOptions.hideOnDesktop) {
      if (childOptions.desktopAlign === DesktopAlign.Right) {
        rightNavContainer.push(navbarChild);
      }
      if (childOptions.desktopAlign === DesktopAlign.Left) {
        leftNavContainer.push(navbarChild);
      }
    }
    if (childOptions?.mobileMenu === MobileMenuType.Own || childOptions.mobileMenu === MobileMenuType.None) {
      mobileMenuItems.push(navbarChild);
    } else {
      mainMenuDrawer.push(navbarChild);
    }
  });
  return {
    containers: {
      leftNavContainer,
      rightNavContainer,
      mainMenuDrawer,
      mobileMenuItems,
    },
    renderCustomHeaderOverflow,
  };
};

export const navbarUtils = {
  childrenSorter,
};
