import { css } from "@emotion/react";
import {
  colors,
  dimension,
  fontSizes,
  letterSpacings,
  lineHeights,
  spacing,
  spacingBaseUnit,
} from "../../../../../../core";

const styles = {
  fullScreenContainer: (open: boolean, height?: number) =>
    css({
      backgroundColor: colors.white.hex,
      position: "fixed",
      top: dimension.header.height,
      left: 0,
      right: 0,
      height: open ? `${height + spacingBaseUnit.mobile * 6}px` : 0,
      overflowY: "hidden",
      visibility: open ? "visible" : "hidden",
      transition: "height 200ms ease, visibility 200ms 50ms ease",
      boxShadow: "0 0 4px 0 rgba(0,0,0,0.6)",
    }),
  columnsContainer: () =>
    css({
      margin: `${spacing.baseUnitMobile3} auto`,
      maxWidth: "1200px",
      display: "flex",
      flexDirection: "row",
      "& > div + div": {
        borderLeft: `1px solid ${colors.disabledGrey.hex}`,
      },
    }),
  menuColumn: () =>
    css({
      maxWidth: "300px",
      display: "flex",
      flexDirection: "column",
      padding: `0 ${spacing.baseUnitMobile3}`,
      rowGap: spacing.baseUnitMobile2,
      "&> a": {
        fontSize: fontSizes.body.horse,
        letterSpacing: letterSpacings.body.horse,
        lineHeight: lineHeights.body.horse,
        color: colors.headlineGrey.hex,
        fontWeight: "inherit",
        transition: "font-weight 100ms ease-in-out",
        "& div[data-navitem]": {
          display: "inline-block",
          "&::before": {
            display: "block",
            content: "attr(data-navitem)",
            fontWeight: "bold",
            height: 0,
            overflow: "hidden",
            visibility: "hidden",
          },
        },
        "&:hover": {
          textDecoration: "none",
          color: colors.headlineGrey.hex,
          fontWeight: "600",
        },
      },
    }),
  menuIcon: () =>
    css({
      height: "18px",
      width: "18px",
      margin: "3px",
    }),
  linkTitle: () =>
    css({
      display: "flex",
      columnGap: spacing.baseUnitMobile1,
      height: "24px",
    }),
  linkDescription: () =>
    css({
      fontSize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      lineHeight: lineHeights.body.mouse,
      marginBottom: spacing.baseUnitMobile1,
    }),
  categoryTitle: () =>
    css({
      textTransform: "uppercase",
      fontSize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      lineHeight: lineHeights.body.mouse,
      color: colors.lightGrey.hex,
      fontWeight: "bold",
    }),
};

export { styles };
