import {
  DesktopAlign,
  ItemTypes,
  MobileMenuType,
  Navbar,
  NavbarItem,
  Placement,
} from "@carfax/web-ui/components/Navbar";
import HeaderLogo from "../HeaderLogo";
import { useStores } from "hooks";
import { Icons } from "@carfax/web-ui/elements/Icon";
import { observer, useTranslation } from "app";
import { languageConfig } from "i18n/language-config";
import { useNavRouting, useAccountMenuSetup } from "../hooks";
import { AccountInfo } from "../AccountInfo";
import { HeaderProps } from "..";

interface Props extends HeaderProps {
  isAuthenticated: boolean;
}

const LoginOnlyNavbar: React.FC<Props> = observer(
  ({ hideNavInHeader, languageOptions, loginRedirect, isAuthenticated }) => {
    const { getActionForLogIn } = useNavRouting();
    const accountMenu = useAccountMenuSetup();
    const { userStore } = useStores();
    const { t, i18n } = useTranslation(["common"]);

    const language = languageConfig.mapLocaleToLanguageShort(i18n.language);
    const labels = {
      close: t("header.menu.pricing"),
    };

    return (
      <Navbar logo={<HeaderLogo />} renderOnlyLogin={true} hideNavInHeader={hideNavInHeader} labels={labels}>
        <NavbarItem
          title={t("dropdown.language")}
          key="language-dropdown-navitem"
          activeSubItem={t(`dropdown.${language}`)}
          options={{
            type: ItemTypes.Dropdown,
            desktopAlign: DesktopAlign.Right,
            mobileMenu: MobileMenuType.Main,
            dropdownAlignment: Placement.bottomStart,
          }}
          dropdownItems={languageOptions}
          testSelector="language-toggle"
        />
        {!isAuthenticated ? (
          <NavbarItem
            title={t("header.menu.login")}
            key="login-navitem"
            action={getActionForLogIn(loginRedirect)}
            options={{ desktopAlign: DesktopAlign.Right, type: ItemTypes.Button, mobileMenu: MobileMenuType.None }}
            testSelector="login-link-redirect"
          />
        ) : (
          <NavbarItem
            title={t("header.menu.myCarfax")}
            key="account-dropdown-navitem"
            icon={Icons.accountOutline}
            options={{ desktopAlign: DesktopAlign.Right, type: ItemTypes.Dropdown, mobileMenu: MobileMenuType.Own }}
            dropdownItems={accountMenu}
            customElement={{
              sideMenu: <AccountInfo userEmail={userStore.email} />,
              dropdownMenu: <AccountInfo userEmail={userStore.email} />,
            }}
            testSelector="account-area-toggle"
          />
        )}
      </Navbar>
    );
  }
);

export { LoginOnlyNavbar };
