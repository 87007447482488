import { css } from "@emotion/react";
import {
  colors,
  mediaQuery,
  dimension,
  spacing,
  fontSizes,
  lineHeights,
  letterSpacings,
  spacingBaseUnit,
} from "../../../../../../core";

const styles = {
  dropdownBody: (open: boolean, isDashboardArea: boolean, height: number) =>
    css({
      backgroundColor: colors.white.hex,
      position: "absolute",
      width: "auto",
      padding: `0 ${spacing.baseUnitMobile3}`,
      top: dimension.header.height,
      left: isDashboardArea ? "auto" : `calc(0% - 24px)`,
      right: 0,
      marginLeft: "auto",
      marginRight: "auto",
      height: open ? `${height + spacingBaseUnit.mobile * 6}px` : 0,
      visibility: open ? "visible" : "hidden",
      overflowY: "hidden",
      transition: "height 200ms ease, visibility 200ms 50ms ease",
      boxShadow: "0 0 4px 0 rgba(0,0,0,0.6)",
    }),
  menuTitle: () =>
    css({
      color: colors.lightGrey.hex,
      fontWeight: "bold",
      fontSize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      lineHeight: lineHeights.body.mouse,
      textTransform: "uppercase",
      marginTop: spacing.baseUnitMobile3,
    }),
  subMenuLink: () =>
    css({
      color: colors.headlineGrey.hex,
      margin: `${spacing.baseUnitMobile3} 0 `,
      fontWeight: "normal",
      display: "flex",
      "& div[data-navitem]": {
        display: "inline-block",
        "&::before": {
          display: "block",
          content: "attr(data-navitem)",
          fontWeight: "bold",
          height: 0,
          overflow: "hidden",
          visibility: "hidden",
        },
      },
      "&:hover": {
        textDecoration: "none",
        fontWeight: "bold",
      },
      "&:last-of-type": {
        marginBottom: spacing.baseUnitMobile1,
      },
    }),
  iconWrap: () =>
    css({
      marginRight: spacing.baseUnitMobile1,
    }),
  itemsContainer: () =>
    css({
      marginBottom: spacing.baseUnitMobile3,
      "& a:first-of-type li": {
        marginTop: `-${spacing.baseUnitMobile1}`,
      },
      "& a:last-of-type li": {
        marginBottom: `-${spacing.baseUnitMobile1}`,
        [mediaQuery.desktopOrLarger]: {
          marginBottom: `-${spacing.baseUnitMobile2}`,
        },
      },
    }),
};

export { styles };
