import { css } from "@emotion/react";
import { colors, fontSizes, lineHeights, spacing } from "../../../../../core";

const styles = {
  sideSubmenu: () =>
    css({
      color: colors.white.hex,
      flexDirection: "column",
      borderBottom: `1px solid ${colors.white.hex}`,
      justifyContent: "left",
      alignItems: "center",
      display: "flex",
      "&> a": {
        display: "flex",
        padding: spacing.baseUnitMobile2,
        svg: {
          marginRight: spacing.baseUnitMobile1,
        },
        color: colors.headlineGrey.hex,
        width: "100%",
        cursor: "pointer",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textTransform: "capitalize",
        fontWeight: 400,
        fontSize: fontSizes.body.horse,
        flex: 1,
        textAlign: "left",
        lineHeight: lineHeights.body.horse,
        position: "relative",
        "&:hover": {
          textDecoration: "none",
        },
        "&.active": {
          fontWeight: 700,
        },
      },
    }),
};

export { styles };
