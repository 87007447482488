import React, { useState } from "react";
import { DropdownItem } from "../../../NavbarItem";
import { Icon, Icons } from "../../../../../elements/Icon";
import Collapsible from "../../../../../elements/Collapsible/Collapsible";
import { Link } from "../../common/Link";
import { styles } from "./Dropdown.styles";

export type MobileDropdownProps = {
  testSelector: string;
  title: string;
  dropdownItems?: DropdownItem[];
  setIsDrawerOpen?: (boolean) => void;
  isDrawerOpen?: boolean;
};

export const MobileDropdown: React.FC<MobileDropdownProps> = ({
  testSelector,
  title,
  dropdownItems,
  isDrawerOpen = false,
  setIsDrawerOpen = () => {},
}) => {
  const [showSubmenuInSideMenu, setShowSubmenuInSideMenu] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <>
      <a
        tabIndex={1}
        data-testid={testSelector}
        onClick={() => {
          setShowSubmenuInSideMenu(!showSubmenuInSideMenu);
        }}
        onKeyDown={(e) => e.key === "Tab" && setShowSubmenuInSideMenu(!showSubmenuInSideMenu)}
      >
        <div css={styles.menuItemWrapper}>
          <Icon icon={Icons.arrowThin} css={styles.arrowSideMenuIcon(showSubmenuInSideMenu)} />
          {title}
        </div>
      </a>
      <Collapsible show={showSubmenuInSideMenu}>
        <div key={`subitem-menu-container-${title}`} css={styles.menuItemWrapper}>
          {dropdownItems && (
            <ul suppressHydrationWarning>
              {dropdownItems.map((subItem: DropdownItem) => (
                <li key={`subitem-side-menu-${subItem.label}`} css={styles.sideSubmenu()}>
                  <Link
                    tabIndex={1}
                    key={`dropdown-item-${title}-${subItem.label}`}
                    testSelector={`${subItem.testSelector}-mobile`}
                    action={subItem?.action}
                    cleanUp={() => {
                      toggleDrawer();
                    }}
                  >
                    {subItem.label}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </div>
      </Collapsible>
    </>
  );
};
