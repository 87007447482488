import React, { PropsWithChildren, useEffect, useRef } from "react";
import { styles } from "./DrawerWrapper.styles";
import { Icon, Icons } from "../../../../../elements/Icon";
import { createPortal } from "react-dom";
import useMountTransition from "./hasMountTransition";
import { useClickAway } from "../../../../../hooks";

interface Props {
  open?: boolean;
  orientation: DrawerOrientation;
  onClose?: (event?: React.MouseEvent<HTMLElement>) => void;
  menuTitle: string;
  customSubTitleElement?: any;
  closeLabel?: string;
}

export enum DrawerOrientation {
  left = "left",
  right = "right",
}

const DrawerWrapper: React.FC<PropsWithChildren<Props>> = ({
  open,
  orientation,
  menuTitle,
  onClose: handleDrawerClose,
  customSubTitleElement,
  children,
  closeLabel = "Close",
}) => {
  const body = useRef<HTMLBodyElement | null>(null);
  const hasTransitionedIn = useMountTransition(open, 300);
  const orientationRight = orientation === DrawerOrientation.right;
  const drawerRef = useRef<HTMLDivElement>(null);
  const closeButtonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    body.current = document.querySelector("body");
  }, []);

  useEffect(() => {
    if (open && orientationRight && closeButtonRef.current) {
      closeButtonRef.current.focus();
    }
  }, [open]);

  useClickAway(drawerRef, handleDrawerClose, !open);

  const drawerBody =
    hasTransitionedIn || open ? (
      <div role="presentation" id={`drawer-container-${menuTitle}`} css={styles.hideOnDesktop}>
        <div css={[styles.drawer(orientationRight), hasTransitionedIn && open ? styles.rollOut() : ""]} ref={drawerRef}>
          <div
            css={styles.headerWrapper}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div css={styles.firstBar}>
              <h6>{menuTitle}</h6>
              <button
                css={styles.closeButton}
                onClick={handleDrawerClose}
                aria-label={closeLabel}
                tabIndex={orientationRight ? 0 : 2}
                ref={closeButtonRef}
              >
                <Icon icon={Icons.clear} />
              </button>
            </div>
            {customSubTitleElement && React.cloneElement(customSubTitleElement, { onClose: handleDrawerClose })}
          </div>
          <div css={styles.navItems}>{children}</div>
        </div>
        <div css={styles.fullscreenFade} />
      </div>
    ) : null;

  return body.current ? createPortal(drawerBody, body.current, `drawer-portal-${menuTitle}`) : null;
};

export default DrawerWrapper;
