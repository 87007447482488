import { mediaQuery, spacing } from "../../../core/styles";
import { css } from "@emotion/react";

export const styles = {
  navigationWrapper: () =>
    css({
      display: "block",
      width: "100%",
      [mediaQuery.desktopOrLarger]: {
        display: "none",
      },
    }),
  leftSiteNav: () =>
    css({
      display: "flex",
      width: "50%",
      justifyContent: "space-between",
      alignItems: "center",
      [mediaQuery.smallerThanMobile]: {
        justifyContent: "left",
      },
    }),
  logoStyles: () =>
    css({
      marginLeft: "24px",
    }),

  naviLinks: () =>
    css({
      display: "flex",
      flexDirection: "row",
      listStyle: "none",
      margin: 0,
      padding: 0,
      position: "relative",
      right: "unset",
      top: "0",
      flex: 1,
      justifyContent: "space-between",
      flexGrow: 1,
    }),
  groupItems: () =>
    css({
      display: "flex",
      flexDirection: "row",
      "li ~ li": {
        marginLeft: `${spacing.baseUnitMobile3}`,
      },
      svg: {
        marginTop: "4px",
      },
    }),
};
