import { css } from "@emotion/react";
import { colors, dimension, fontSizes, letterSpacings } from "../../../../../core";

const styles = {
  menuItem: () =>
    css({
      fontWeight: 400,
      fontSize: fontSizes.body.horse,
      flex: 1,
      alignItems: "center",
      color: colors.white.hex,
      width: "initial",
      position: "relative",
      transition: "color 250ms ease",
      minHeight: dimension.header.height,
      border: "none",
      background: "none",
      letterSpacing: letterSpacings.body.horse,
      fontFamily: "inherit",
      padding: "0",
    }),
  menuItemIconWrapper: () =>
    css({
      fill: colors.white.hex,
      width: "24px",
      height: "24px",
      color: colors.white.hex,
    }),
};

export { styles };
