import { colors, spacing, fontSizes, letterSpacings, lineHeights } from "../../../../../core";
import { css } from "@emotion/react";

const styles = {
  columnTitle: () =>
    css({
      padding: `${spacing.baseUnitMobile3} ${spacing.baseUnitMobile4} 0`,
      textTransform: "uppercase",
      fontSize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      lineHeight: lineHeights.body.mouse,
      color: colors.lightGrey.hex,
      fontWeight: "bold",
    }),
  menuItemWrapper: () =>
    css({
      margin: `0 ${spacing.baseUnitMobile2}`,
    }),
  arrowSideMenuIcon: (open: boolean) =>
    css({
      padding: 0,
      position: "absolute",
      top: "50%",
      left: spacing.baseUnitMobile2,
      transform: open ? "rotate(-90deg) translate(0, -50%)" : "rotate(90deg) translate(0, -50%)",
      transformOrigin: "top",
      transition: "transform 0.3s ease",
      svg: { padding: "4px", fill: colors.headlineGrey.hex },
    }),
  sideSubmenu: (isHidden?: boolean) =>
    css({
      color: colors.white.hex,
      flexDirection: "column",
      borderBottom: `1px solid ${colors.white.hex}`,
      justifyContent: "left",
      alignItems: "center",
      display: "flex",
      "&> a": {
        color: colors.headlineGrey.hex,
        width: "100%",
        cursor: "pointer",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textTransform: "capitalize",
        fontWeight: 400,
        fontSize: fontSizes.body.horse,
        flex: 1,
        textAlign: "left",
        lineHeight: lineHeights.body.horse,
        padding: `${spacing.baseUnitMobile2} ${spacing.baseUnitMobile4}`,
        display: !isHidden && "inline-flex",
        position: "relative",
        "&:hover": {
          textDecoration: "none",
        },
        "&.active": {
          fontWeight: 700,
        },
      },
    }),
  itemIcon: () =>
    css({
      padding: "3px",
      marginRight: spacing.baseUnitMobile1,
    }),
};

export { styles };
