import { MenuItem } from "@carfax/web-ui/components/Navbar/MenuItem";
import { Icons } from "@carfax/web-ui/elements/Icon";

type Props = {
  userEmail: string;
};
const AccountInfo: React.FC<Props> = ({ userEmail }) => {
  return (
    <MenuItem icon={Icons.account}>
      <div suppressHydrationWarning>{userEmail}</div>
    </MenuItem>
  );
};

export { AccountInfo };
