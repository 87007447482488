import React, { useRef } from "react";
import { styles } from "./DropdownContent.styles";
import { Icon } from "../../../../../../elements/Icon";
import { DropdownItem } from "../../../../NavbarItem/NavbarItem";
import { BackShadow } from "../../../../../../elements/BackShadow";
import { Link } from "../../../common/Link/Link";
import { useClickAway, useTabOut } from "../../../../../../hooks";

interface IProps {
  dropdownItems?: DropdownItem[];
  anchorElement: { current: HTMLInputElement };
  menuTitle?: string;
  open: boolean;
  setOpen: (boolean) => void;
  customSubTitleElement?: React.ReactNode;
}

const DropdownContent: React.FC<IProps> = ({
  dropdownItems = [],
  anchorElement,
  menuTitle,
  open,
  setOpen,
  customSubTitleElement,
}) => {
  const ref = useRef<HTMLDivElement>();
  const refContent = useRef<HTMLDivElement>();
  const height = refContent.current?.clientHeight;

  const isDashboardArea = !!dropdownItems[0].testSelector;
  const handleClose = (event) => {
    if (anchorElement.current && anchorElement.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };
  useClickAway(ref, handleClose, height == 0 || !open);
  useTabOut(ref, handleClose, height == 0 || !open);
  return (
    <div>
      <div ref={ref}>
        <div css={styles.dropdownBody(open, isDashboardArea, height)}>
          <div ref={refContent}>
            {menuTitle && <div css={styles.menuTitle}>{menuTitle}</div>}
            <div>{customSubTitleElement}</div>
            <div css={styles.itemsContainer}>
              {dropdownItems.map((subItem: DropdownItem) => (
                <Link
                  css={styles.subMenuLink}
                  action={subItem.action}
                  key={`dropdown-item-${menuTitle}-${subItem.label}`}
                  testSelector={subItem.testSelector}
                  cleanUp={() => setOpen(false)}
                >
                  {subItem.icon && <Icon css={styles.iconWrap} icon={subItem.icon} />}
                  <div data-navitem={subItem.label}>{subItem.label}</div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
      {open && <BackShadow />}
    </div>
  );
};

export default DropdownContent;
