import { useTranslation } from "app";
import { Icons } from "@carfax/web-ui/elements/Icon";
import { languageConfig } from "i18n/language-config";
import { useNavRouting } from "./useNavRouting";
import { linkUtil } from "utils/i18n/link-util";
import { useRoutes } from "domain/Routing/hooks/useRoutes";

const useAccountMenuSetup = () => {
  const { logoutHandler } = useNavRouting();
  const routes = useRoutes();
  const { t, i18n } = useTranslation(["common"]);
  const language = languageConfig.mapLocaleToLanguageShort(i18n.language);

  return [
    {
      label: t("header.menu.accountDashboard"),
      action: { href: linkUtil.i18nLinkWithLanguage(routes.account, language) },
      icon: Icons.dashboard,
      testSelector: "dashboard-link",
    },
    {
      label: t("header.menu.accountReports"),
      action: { href: linkUtil.i18nLinkWithLanguage(routes.accountReports, language) },
      icon: Icons.reports,
      testSelector: "reports-link",
    },
    {
      label: t("header.menu.accountSettings"),
      action: { href: linkUtil.i18nLinkWithLanguage(routes.accountSettings, language) },
      icon: Icons.settings,
      testSelector: "settings-link",
    },
    {
      label: t("header.menu.logout"),
      icon: Icons.logout,
      action: {
        onClick: (e) => {
          e.preventDefault();
          logoutHandler();
        },
      },
      testSelector: "logout-link",
    },
  ];
};
export { useAccountMenuSetup };
