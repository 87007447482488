import { css } from "@emotion/react";
import { colors } from "../../../../../core";

const styles = {
  menuItemIconWrapper: () =>
    css({
      fill: colors.white.hex,
      width: "24px",
      height: "24px",
      color: colors.white.hex,
    }),
};

export { styles };
