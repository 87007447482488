import React from "react";
import { Icon, Icons } from "../../../../../elements/Icon";
import DropdownContent from "./DropdownContent/DropdownContent";
import { DropdownItem } from "../../../NavbarItem";
import { CustomElement } from "../../../NavbarItem/NavbarItem";
import { styles } from "./Dropdown.styles";

export type DropdownProps = {
  setOpenMenu: (open: boolean) => void;
  openMenu: boolean;
  openDropdownMenu: boolean;
  isMobile?: boolean;
  testSelector?: string;
  icon?: string;
  title?: string;
  activeSubItem?: string;
  dropdownItems?: DropdownItem[];
  customElement?: CustomElement;
};

export const Dropdown: React.FC<DropdownProps> = ({
  testSelector,
  icon,
  title,
  activeSubItem,
  dropdownItems = [],
  customElement,
  openDropdownMenu,
  openMenu,
  setOpenMenu,
  isMobile = false,
}) => {
  const displayName = icon ? <Icon icon={icon} css={styles.menuItemIconWrapper} /> : title;
  const anchorElement = React.useRef();
  return (
    <>
      <button
        data-testid={testSelector}
        css={styles.dropdownTrigger(openMenu)}
        ref={anchorElement}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        key={`top-bar-nav-item-${testSelector}`}
        aria-label={title}
      >
        <div suppressHydrationWarning data-navitem={title || ""}>
          {!icon && activeSubItem ? activeSubItem : displayName}
        </div>
        {<Icon icon={Icons.arrowThin} css={styles.arrowIcon(openDropdownMenu)} />}
      </button>
      {!isMobile && dropdownItems.length > 0 && (
        <div css={styles.hideOnMobile}>
          <DropdownContent
            anchorElement={anchorElement}
            dropdownItems={dropdownItems}
            menuTitle={title}
            open={openMenu}
            setOpen={setOpenMenu}
            customSubTitleElement={customElement?.dropdownMenu}
          />
        </div>
      )}
    </>
  );
};
