import React, { useState } from "react";
import { MegaMenuColumn } from "../../desktop/MegaMenu";
import Collapsible from "../../../../../elements/Collapsible/Collapsible";
import { Icon, Icons } from "../../../../../elements/Icon";
import { styles } from "./MegaMenu.styles";
import { Link } from "../../common/Link";

export type MobileMegaMenuProps = {
  title: string;
  megaMenuColumns?: MegaMenuColumn[];
  testSelector: string;
  isDrawerOpen?: boolean;
  setIsDrawerOpen?: (boolean) => void;
};

export const MobileMegaMenu: React.FC<MobileMegaMenuProps> = ({
  testSelector,
  title,
  megaMenuColumns,
  isDrawerOpen = false,
  setIsDrawerOpen = () => {},
}) => {
  const [showSubmenuInSideMenu, setShowSubmenuInSideMenu] = useState(false);
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <>
      <a
        tabIndex={1}
        data-testid={testSelector}
        onClick={() => {
          setShowSubmenuInSideMenu(!showSubmenuInSideMenu);
        }}
        onKeyDown={(e) => e.key === "Tab" && setShowSubmenuInSideMenu(!showSubmenuInSideMenu)}
      >
        <div css={styles.menuItemWrapper}>
          {<Icon icon={Icons.arrowThin} css={styles.arrowSideMenuIcon(showSubmenuInSideMenu)} />}
          {title}
        </div>
      </a>
      <Collapsible show={showSubmenuInSideMenu}>
        <div key={`subitem-menu-container-${title}`} css={styles.menuItemWrapper}>
          {megaMenuColumns &&
            megaMenuColumns.map((column: MegaMenuColumn) => {
              return (
                <div key={`subitem-side-megamenu-${column.title}`}>
                  {column?.title && <div css={styles.columnTitle}>{column.title}</div>}
                  <ul>
                    {column.items.map((item) => {
                      return (
                        <li key={`subitem-side-megamenu-item-${item.name}`} css={styles.sideSubmenu(item.isHidden)}>
                          <Link
                            tabIndex={1}
                            action={item.action}
                            hidden={item.isHidden}
                            testSelector={item?.testSelector ? `${item.testSelector}-mobile` : null}
                            className={item?.isActive ? "active" : ""}
                            cleanUp={() => toggleDrawer()}
                          >
                            {item.icon && <Icon css={styles.itemIcon} icon={item.icon} />}
                            <div>{item.name}</div>
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              );
            })}
        </div>
      </Collapsible>
    </>
  );
};
