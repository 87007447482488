import React from "react";
import { ActionProps, Link } from "../../common/Link";
import { styles } from "./MenuButton.styles";

export type MobileMenuButtonProps = {
  testSelector: string;
  title: string;
  isActive?: boolean;
  icon?: string;
  action?: ActionProps;
  isDrawerOpen?: boolean;
  setIsDrawerOpen?: (boolean) => void;
};

export const MobileMenuButton: React.FC<MobileMenuButtonProps> = ({
  testSelector,
  title,
  isActive = false,
  action,
  isDrawerOpen = false,
  setIsDrawerOpen = () => {},
}) => {
  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  return (
    <Link
      tabIndex={1}
      testSelector={testSelector}
      className={isActive ? "active" : ""}
      action={action}
      key={`top-bar-nav-item-${testSelector}`}
      cleanUp={() => toggleDrawer()}
    >
      <div css={styles.menuItemWrapper}>{title}</div>
    </Link>
  );
};
