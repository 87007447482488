import React from "react";
import { Icon } from "../../../../../elements/Icon";
import { ActionProps, Link } from "../../common/Link";
import { styles } from "./MenuButton.styles";

export type MenuButtonProps = {
  testSelector: string;
  title: string;
  isActive?: boolean;
  icon?: string;
  action?: ActionProps;
};

export const MenuButton: React.FC<MenuButtonProps> = ({ testSelector, title, isActive = false, action, icon }) => {
  const displayName = icon ? <Icon icon={icon} css={styles.menuItemIconWrapper} /> : title;

  return (
    <Link
      testSelector={testSelector}
      className={isActive ? "active" : ""}
      action={action}
      key={`top-bar-nav-item-${testSelector}`}
      aria-label={title}
    >
      <div data-navitem={title || ""}>{displayName}</div>
    </Link>
  );
};
