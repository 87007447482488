import { ActionProps } from "@carfax/web-ui/components/Navbar/components/common/Link";
import { languageConfig } from "i18n/language-config";
import { useTranslation } from "app";
import { useRouter } from "next/router";
import { linkUtil } from "utils/i18n/link-util";
import { useRoutes } from "domain/Routing/hooks/useRoutes";
import { RoutesKeys } from "domain/Routing/customRouteMap";
import { Routes } from "utils/config/routes";
import { navigationUtil } from "../utils/navigationUtil";
import { logoutUtil } from "domain/User/utils/logoutUtil";
import { useStores } from "hooks";
import { useAuth0 } from "@auth0/auth0-react";
import { getSocialLoginRedirectUri } from "domain/User/utils/socialLoginUtils";
import routerService from "domain/Routing/utils/router-service";

const useNavRouting = () => {
  const { i18n } = useTranslation(["common"]);
  const router = useRouter();
  const language = languageConfig.mapLocaleToLanguageShort(i18n.language);
  const getLink = (route) => linkUtil.i18nLinkWithLanguage(route, language);
  const routes = useRoutes();
  const { userStore } = useStores();
  const { logout } = useAuth0();

  const nextRouting = (link) => (e) => {
    if (link) {
      e.preventDefault();
      router.push(link);
    }
  };
  const getActionForSoftRoute = (path?: RoutesKeys): ActionProps => {
    const href = getLink(routes?.[path] || Routes.HOME);
    return {
      href,
      onClick: nextRouting(href),
    };
  };
  const getActionForLogIn = (loginRedirect?: string): ActionProps => {
    const href = loginRedirect
      ? Routes.accountLoginWithRedirect(loginRedirect)
      : linkUtil.i18nLinkWithLanguage(routes.accountLogin, language);
    return {
      href,
      onClick: nextRouting(href),
    };
  };
  const getActionForExternalRoute = (path: RoutesKeys): ActionProps => {
    const href = getLink(routes[path]);
    return {
      href,
      openInNewTab: true,
    };
  };
  const isCurrentPage = (path: RoutesKeys): boolean => {
    return navigationUtil.isCurrentPage(routes?.[path], router.asPath);
  };
  const isSubPage = (path: RoutesKeys): boolean => {
    return navigationUtil.isParentOfCurrentPage(routes?.[path], router.asPath);
  };

  const logoutHandler = async () => {
    await logoutUtil.logout();
    if (userStore.isSocialLogin) {
      await logout({ logoutParams: { returnTo: getSocialLoginRedirectUri(Routes.HOME) } });
    }
    routerService.routeTo(Routes.HOME);
  };
  return {
    getActionForSoftRoute,
    getActionForLogIn,
    getActionForExternalRoute,
    isCurrentPage,
    isSubPage,
    logoutHandler,
  };
};
export { useNavRouting };
