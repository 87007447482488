import React from "react";
import { styles } from "./MegaMenu.styles";
import MegaMenuContent, { MegaMenuColumn } from "./MegaMenuContent/MegaMenuContent";
import { Icon, Icons } from "../../../../../elements/Icon";

export type MegaMenuProps = {
  setOpenMenu: (open: boolean) => void;
  openMenu: boolean;
  openDropdownMenu: boolean;
  testSelector: string;
  title: string;
  megaMenuColumns?: MegaMenuColumn[];
  isMobile?: boolean;
};

export const MegaMenu: React.FC<MegaMenuProps> = ({
  testSelector,
  title,
  megaMenuColumns = [],
  openDropdownMenu,
  openMenu,
  setOpenMenu,
  isMobile = false,
}) => {
  const anchorElement = React.useRef();
  return (
    <>
      <button
        id="mega-menu-button"
        data-testid={testSelector}
        css={styles.menuTrigger(openMenu)}
        ref={anchorElement}
        onClick={() => {
          setOpenMenu(!openMenu);
        }}
        aria-expanded={openMenu}
        aria-controls={`mega-menu-${title}`}
        key={`top-bar-nav-item-${testSelector}`}
      >
        <div data-navitem={title}>{title}</div>
        <Icon icon={Icons.arrowThin} css={styles.arrowIcon(openDropdownMenu)} />
      </button>
      {!isMobile && megaMenuColumns.length > 0 && (
        <div css={styles.hideOnMobile} id={`mega-menu-${title}`} aria-hidden={!openMenu}>
          <MegaMenuContent
            setOpen={setOpenMenu}
            open={openMenu}
            megaMenuColumns={megaMenuColumns}
            anchorElement={anchorElement}
          />
        </div>
      )}
    </>
  );
};
