import React, { useEffect, useState } from "react";
import { observer } from "app";
import { DropdownItem } from "@carfax/web-ui/components/Navbar";
import { useStores } from "hooks";
import { LoginOnlyNavbar, FullNavbar } from "./config";

export interface Props {
  hideNavInHeader?: boolean;
  showOnlyLoginInNavbar?: boolean;
  loginRedirect?: string;
  languageOptions: DropdownItem[];
}

const Header: React.FC<Props> = observer(
  ({ hideNavInHeader = false, showOnlyLoginInNavbar = false, loginRedirect, languageOptions = [] }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const { userStore } = useStores();

    const commonProps = {
      loginRedirect,
      languageOptions,
      hideNavInHeader,
      isAuthenticated,
    };

    useEffect(() => {
      setIsAuthenticated(userStore.isAuthenticated);
    }, [userStore.isAuthenticated]);
    return (
      userStore && (showOnlyLoginInNavbar ? <LoginOnlyNavbar {...commonProps} /> : <FullNavbar {...commonProps} />)
    );
  }
);

export default Header;
