import { spacing } from "../../../../../core";
import { css } from "@emotion/react";

const styles = {
  menuItemWrapper: () =>
    css({
      margin: `0 ${spacing.baseUnitMobile2}`,
    }),
};

export { styles };
