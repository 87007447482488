import React from "react";
import { DropdownItem } from "../../../NavbarItem";
import DrawerWrapper, { DrawerOrientation } from "../../common/DrawerWrapper";
import { Link } from "../../common/Link";
import { Icon } from "../../../../../elements/Icon";
import { styles } from "./DrawerMenu.styles";

type MobileOwnMenuProps = {
  openMenu: boolean;
  setOpenMenu: (boolean) => void;
  title: string;
  dropdownItems: DropdownItem[];
  customElement: any;
  labels?: Record<string, string>;
};

export const DrawerMenu: React.FC<MobileOwnMenuProps> = ({
  openMenu,
  setOpenMenu,
  title,
  dropdownItems,
  customElement,
  labels = {},
}) => {
  return (
    <DrawerWrapper
      open={openMenu}
      orientation={DrawerOrientation.right}
      onClose={() => setOpenMenu(false)}
      menuTitle={title}
      customSubTitleElement={customElement?.sideMenu}
      closeLabel={labels?.close}
    >
      <ul key={`subitem-menu-container-${title}`}>
        {dropdownItems &&
          dropdownItems.map((dropdownItem) => (
            <li key={`subitem-side-menu-${dropdownItem.label}`} css={styles.sideSubmenu}>
              <Link
                action={dropdownItem.action}
                data-testid={dropdownItem?.testSelector ? `${dropdownItem.testSelector}-mobile` : null}
              >
                {dropdownItem.icon && <Icon icon={dropdownItem.icon} />}
                {dropdownItem.label}
              </Link>
            </li>
          ))}
      </ul>
    </DrawerWrapper>
  );
};
