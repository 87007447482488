import { useTranslation } from "app";
import { Icons } from "@carfax/web-ui/elements/Icon";
import { languageConfig } from "i18n/language-config";
import { useNavRouting } from "./useNavRouting";
import { Language } from "i18n/country";

const useMegaMenuSetup = () => {
  const { getActionForExternalRoute, isCurrentPage, isSubPage, getActionForSoftRoute } = useNavRouting();
  const { t, i18n } = useTranslation(["common"]);
  const language = languageConfig.mapLocaleToLanguageShort(i18n.language);

  return [
    {
      items: [
        {
          name: t("header.menu.supportCenter.title"),
          action: getActionForExternalRoute("faq"),
          icon: Icons.supportCenterIcon,
          testSelector: "support-center-link",
          description: t("header.menu.supportCenter.description"),
          isActive: isCurrentPage("faq"),
        },
        {
          name: "Blog",
          action: getActionForSoftRoute("blog"),
          icon: Icons.blogIcon,
          testSelector: "menu-link-internal",
          description: t("header.menu.blog.description"),
          isActive: isSubPage("blog"),
        },
      ],
    },
    {
      title: t("header.menu.categories.tools"),
      items: [
        {
          name: t("header.menu.calcoBolloAuto"),
          action: getActionForSoftRoute("bolloCalculator"),
          testSelector: "calco-bollo-auto-link",
          isActive: isCurrentPage("bolloCalculator"),
          isHidden: language !== Language.ITALIAN,
        },
        {
          name: t("header.menu.freeVinDecoder"),
          action: getActionForSoftRoute("freeVinDecoder"),
          testSelector: "vin-decoder-link",
          isActive: isCurrentPage("freeVinDecoder"),
        },
        {
          name: t("header.menu.bmwVinDecoder"),
          action: getActionForSoftRoute("bmwVinDecoder"),
          testSelector: "bmw-vin-decoder-link",
          isActive: isCurrentPage("bmwVinDecoder"),
        },
      ],
    },
    {
      title: t("header.menu.categories.carChecks"),
      items: [
        {
          name: t("header.menu.mileageCheck"),
          action: getActionForSoftRoute("mileageCheck"),
          testSelector: "mileage-check-link",
          isActive: isCurrentPage("mileageCheck"),
        },
        {
          name: t("header.menu.serviceHistory"),
          action: getActionForSoftRoute("carServiceHistory"),
          testSelector: "service-history-link",
          isActive: isCurrentPage("carServiceHistory"),
        },
        {
          name: t("header.menu.importCheck"),
          action: getActionForSoftRoute("freeImportCheck"),
          testSelector: "import-check-link",
          isActive: isCurrentPage("freeImportCheck"),
        },
      ],
    },
  ];
};
export { useMegaMenuSetup };
