import React, { useMemo, useCallback, useState } from "react";
import { styles } from "./Navbar.styles";
import Headroom from "react-headroom";
import { CustomElementRenderProps, NavbarItemProps } from "./NavbarItem/NavbarItem";
import { NavContainer } from "./NavContainer";
import { MobileNavContainer } from "./MobileNavContainer";
import SideMenuButton from "./SideMenuButton";
import { navbarUtils } from "./Navbar.utils";

type NavbarProps = {
  logo?: React.ReactNode;
  children: React.ReactElement[];
  hideNavInHeader?: boolean;
  renderOnlyLogin?: boolean;
  labels?: Record<string, string>;
};

export type NavbarChild = React.ReactElement<NavbarItemProps>;
type CustomHeaderItem = ({ onClose, isOpen }: CustomElementRenderProps) => React.ReactNode | null;

export type ChildrenCategories = {
  containers: {
    leftNavContainer: NavbarChild[];
    rightNavContainer: NavbarChild[];
    mainMenuDrawer: NavbarChild[];
    mobileMenuItems: NavbarChild[];
  };
  renderCustomHeaderOverflow: CustomHeaderItem;
};

const Navbar: React.FC<NavbarProps> = ({
  children,
  logo,
  hideNavInHeader = false,
  renderOnlyLogin = false,
  labels = {},
}) => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const { containers, renderCustomHeaderOverflow } = useMemo(
    () =>
      navbarUtils.childrenSorter({
        children: children,
        hideNavInHeader: hideNavInHeader,
        searchCallbacks: {
          setIsOpen: setSearchOpen,
          isOpen: searchOpen,
        },
        drawerCallbacks: {
          setIsDrawerOpen: setIsDrawerOpen,
          isDrawerOpen: isDrawerOpen,
        },
      }),
    [children, hideNavInHeader, searchOpen, isDrawerOpen]
  );

  const headerOverflow = useCallback(
    () =>
      renderCustomHeaderOverflow &&
      renderCustomHeaderOverflow({
        onClose: () => {
          setSearchOpen(false);
        },
      }),
    [renderCustomHeaderOverflow]
  );

  const shouldRenderSideMenuButton = () => {
    return !hideNavInHeader && !renderOnlyLogin;
  };

  return (
    <Headroom css={styles.header()} tag="header" disableInlineStyles>
      <nav>
        <div css={styles.headerContentContainer()}>
          <NavContainer logo={logo}>{containers.leftNavContainer}</NavContainer>
          <NavContainer withSeparator>{containers.rightNavContainer}</NavContainer>
          <MobileNavContainer
            sideMenuButton={
              shouldRenderSideMenuButton() && (
                <SideMenuButton isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} labels={labels}>
                  {containers.mainMenuDrawer}
                </SideMenuButton>
              )
            }
            logo={logo}
          >
            {containers.mobileMenuItems}
          </MobileNavContainer>
        </div>
      </nav>
      {searchOpen && <div css={styles.overflowComponentStyling()}>{headerOverflow()}</div>}
    </Headroom>
  );
};
export default Navbar;
