import React from "react";
import { styles } from "./SearchButton.styles";
import { Icon } from "../../../../../elements/Icon";
import { ActionProps } from "../../common/Link";

export type MenuButtonProps = {
  testSelector: string;
  title: string;
  icon?: string;
  action?: ActionProps;
};

export const SearchButton: React.FC<MenuButtonProps> = ({ testSelector, title, action, icon }) => {
  const displayName = icon ? <Icon icon={icon} css={styles.menuItemIconWrapper} /> : title;

  return (
    <button
      css={styles.menuItem}
      data-testid={testSelector}
      onClick={(e) => action?.onClick(e)}
      key={`top-bar-nav-item-${testSelector}`}
      aria-label={title}
    >
      <div data-navitem={title || ""}>{displayName}</div>
    </button>
  );
};
