import { css } from "@emotion/react";
import { colors, dimension, fontSizes, letterSpacings, mediaQuery } from "../../../../../core";

const sideArrowSize = 24;
const openDropdownIndicatorSize = 13;

const styles = {
  menuTrigger: (open: boolean) =>
    css({
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textTransform: "uppercase",
      fontWeight: 400,
      fontSize: fontSizes.body.horse,
      flex: 1,
      alignItems: "center",
      textDecoration: "none",
      display: "flex",
      color: colors.white.hex,
      width: "initial",
      position: "relative",
      transition: "color 250ms ease",
      minHeight: dimension.header.height,
      border: "none",
      background: "none",
      letterSpacing: letterSpacings.body.horse,
      fontFamily: "inherit",
      padding: "0",
      "&::after": {
        content: `""`,
        position: "absolute",
        left: `calc(50% - ${sideArrowSize}px)`,
        bottom: 0,
        transform: open ? "translateY(0)" : "translateY(100%)",
        transition: "transform 200ms ease",
        width: 0,
        height: 0,
        border: `${openDropdownIndicatorSize}px solid transparent`,
        borderBottom: `${openDropdownIndicatorSize}px solid ${colors.white.hex}`,
        zIndex: 2,
      },
    }),
  arrowIcon: (open: boolean) =>
    css({
      alignItems: "center",

      display: "flex",
      justifyContent: "space-between",
      flex: 1,
      width: "24px",
      color: colors.white.hex,
      fill: colors.white.hex,
      padding: "0",
      pointerEvents: "none",
      svg: { padding: "4px" },
      "&&": {
        transform: open ? "rotate(270deg)" : "rotate(90deg)",
      },
    }),
  hideOnMobile: () =>
    css({
      display: "none",
      [mediaQuery.desktopOrLarger]: {
        display: "block",
      },
    }),
};

export { styles };
