import React from "react";
import HeaderLogo from "../HeaderLogo";
import { observer, useTranslation } from "app";
import {
  Navbar,
  NavbarItem,
  ItemTypes,
  DesktopAlign,
  MobileMenuType,
  Placement,
} from "@carfax/web-ui/components/Navbar";
import { languageConfig } from "i18n/language-config";
import { Icons } from "@carfax/web-ui/elements/Icon";
import { navigationUtil } from "../utils/navigationUtil";
import { Routes } from "utils/config/routes";
import { useRoutes } from "domain/Routing/hooks/useRoutes";
import { useStores } from "hooks";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";
import { useMegaMenuSetup, useAccountMenuSetup, useNavRouting } from "../hooks";
import { AccountInfo } from "../AccountInfo";
import { HeaderProps } from "..";

const DesktopSearch = dynamic(() => import("../HeaderSearch/DesktopSearch/DesktopSearch"), {
  ssr: false,
});

const MobileSearch = dynamic(() => import("../HeaderSearch/MobileSearch/MobileSearch"), {
  ssr: false,
});

interface Props extends HeaderProps {
  isAuthenticated: boolean;
}

const FullNavbar: React.FC<Props> = observer(
  ({ hideNavInHeader = false, loginRedirect, isAuthenticated, languageOptions = [] }) => {
    const { getActionForSoftRoute, getActionForLogIn } = useNavRouting();
    const megaMenu = useMegaMenuSetup();
    const accountMenu = useAccountMenuSetup();

    const { t, i18n } = useTranslation(["common"]);
    const { userStore } = useStores();

    const language = languageConfig.mapLocaleToLanguageShort(i18n.language);
    const routes = useRoutes();
    const router = useRouter();
    const labels = {
      close: t("alert.close"),
    };

    const renderDesktopSearch = ({ onClose = () => null }) => {
      return <DesktopSearch onClose={onClose} />;
    };

    return (
      <Navbar logo={<HeaderLogo />} hideNavInHeader={hideNavInHeader} labels={labels}>
        <NavbarItem
          title={t("header.menu.home")}
          key="home-navitem"
          action={getActionForSoftRoute()}
          isActive={navigationUtil.isCurrentPage(Routes.HOME, router.asPath)}
          options={{
            type: ItemTypes.Button,
            desktopAlign: DesktopAlign.Left,
            mobileMenu: MobileMenuType.Main,
            hideOnDesktop: true,
          }}
          testSelector="home-link"
        />
        <NavbarItem
          title={t("header.menu.ourService")}
          key="our-service-navitem"
          action={getActionForSoftRoute("sampleReport")}
          isActive={navigationUtil.isParentOfCurrentPage(routes.sampleReport, router.asPath)}
          options={{ type: ItemTypes.Button, desktopAlign: DesktopAlign.Left, mobileMenu: MobileMenuType.Main }}
          testSelector="our-service-link"
        />
        <NavbarItem
          title={t("header.menu.pricing")}
          key="pricing-navitem"
          action={getActionForSoftRoute("pricing")}
          isActive={navigationUtil.isParentOfCurrentPage(routes.pricing, router.asPath)}
          options={{ type: ItemTypes.Button, desktopAlign: DesktopAlign.Left, mobileMenu: MobileMenuType.Main }}
          testSelector="pricing-link"
        />
        <NavbarItem
          title={t("header.menu.business")}
          key="business-navitem"
          action={getActionForSoftRoute("business")}
          isActive={navigationUtil.isParentOfCurrentPage(routes.business, router.asPath)}
          options={{ type: ItemTypes.Button, desktopAlign: DesktopAlign.Left, mobileMenu: MobileMenuType.Main }}
          testSelector="business-link"
        />
        <NavbarItem
          title={t("header.menu.resources")}
          key="resources-navitem"
          options={{ type: ItemTypes.MegaMenu, desktopAlign: DesktopAlign.Left, mobileMenu: MobileMenuType.Main }}
          megaMenuColumns={megaMenu}
          testSelector="resources-navbar-menu"
        />
        <NavbarItem
          title={t("header.search.label")}
          key="search-navitem"
          testSelector="nav-search-button"
          icon={Icons.searchHeaderTopBar}
          options={{
            type: ItemTypes.Search,
            desktopAlign: DesktopAlign.Right,
            mobileMenu: MobileMenuType.Own,
          }}
          customElement={{ sideMenu: <MobileSearch />, render: renderDesktopSearch }}
        />
        <NavbarItem
          title={t("dropdown.language")}
          key="language-dropdown-navitem"
          activeSubItem={t(`dropdown.${language}`)}
          options={{
            type: ItemTypes.Dropdown,
            desktopAlign: DesktopAlign.Right,
            mobileMenu: MobileMenuType.Main,
            dropdownAlignment: Placement.bottomStart,
          }}
          dropdownItems={languageOptions}
          testSelector="language-toggle"
        />
        {!isAuthenticated ? (
          <NavbarItem
            title={t("header.menu.login")}
            key="login-navitem"
            action={getActionForLogIn(loginRedirect)}
            options={{ desktopAlign: DesktopAlign.Right, type: ItemTypes.Button, mobileMenu: MobileMenuType.None }}
            testSelector="login-link-redirect"
          />
        ) : (
          <NavbarItem
            title={t("header.menu.myCarfax")}
            key="account-dropdown-navitem"
            icon={Icons.accountOutline}
            options={{ desktopAlign: DesktopAlign.Right, type: ItemTypes.Dropdown, mobileMenu: MobileMenuType.Own }}
            dropdownItems={accountMenu}
            customElement={{
              sideMenu: <AccountInfo userEmail={userStore.email} />,
              dropdownMenu: <AccountInfo userEmail={userStore.email} />,
            }}
            testSelector="account-area-toggle"
          />
        )}
      </Navbar>
    );
  }
);

export { FullNavbar };
